export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    homeMainBannerVideoFunctionality();
    homeOtherBannerVideoFunctionality();
    applicationSlider();
  },
};

$(window).on('resize', function () {
  $('.home-applications-section .application-wrp').flickity('resize');
});


function applicationSlider() {
  $('.home-applications-section').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.application-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}


function homeMainBannerVideoFunctionality() {
  setTimeout(function(){
    $('.main-video-wrp .main-banner-video-thumbnail').css('opacity', 0);
    $('.main-video-wrp .banner-video').get(0).play();
  }, 3000);
}

function homeOtherBannerVideoFunctionality() {
  $('.banner-inner-wrp .video-item-wrp').mouseenter(function(){
    $(this).find('.thumb, .play-icon').css('opacity', 0);
    $(this).find('.banner-other-video').get(0).play();
  });

  $('.banner-inner-wrp .video-item-wrp').mouseleave(function(){
    $(this).find('.play-icon').css('opacity', 1);
    $(this).find('.banner-other-video').get(0).pause();
  });
}
